import React from 'react'
import PageLink from '@/link.js';
import Link from 'gatsby-plugin-transition-link';
import cx from 'classnames';
import Helmet from "react-helmet";
import { navigateTo } from "gatsby-link";
import randomAnimation from '../../static/randomAnimation.json';

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const updateStates = () => {
  const html = document.querySelector('html')
  html.classList.add('officeHoursColor')
}

// Run native upload behavior stuff on custom button: #uploadBtn
function getFile() {
  document.getElementById("file").click();
}

function showSuccessMsg() {
    var successMessage = document.getElementById("success");
    var errorMessage = document.getElementById("error");
    var officeHoursForm = document.getElementById("officeHoursForm");
    officeHoursForm.classList.add("hide");
    successMessage.classList.remove("hide");
    errorMessage.classList.add("hide");

    // setTimeout(() => {
    //     officeHoursForm.classList.remove("hide");
    //    successMessage.classList.add("hide");
    // }, 3000)

}

function showErrorMsg() {
    var errorMessage = document.getElementById("error");
    errorMessage.classList.remove("hide");
}

class OfficeHours extends React.Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null }
    this.state = { errorMsg: null }
    this.state = { data: null }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
      var maxAllowedSize = 50*1024*1024;
      document.getElementById("uploadBtn").innerHTML = 'Upload Portfolio PDF';
      if (!e.target.files[0]) {
          return;
      }
      if (e.target.files[0].size > maxAllowedSize) {
        this.setState({ errorMsg: "Please ensure your PDF is under 50MB."});
        e.target.value = null;
        showErrorMsg();
      }
      else {
        var uploadName = e.target.value.split("\\");
        // Maybe add file name to button after chosen
        // document.getElementById("uploadBtn").innerHTML = uploadName[uploadName.length - 1];
        document.getElementById("uploadBtn").innerHTML = 'Upload Ready'
        var errorMessage = document.getElementById("error");
        errorMessage.classList.add("hide");
      }
  };

  handleSubmit = e => {

    e.preventDefault();
    const form = e.target;
      if(!form.name.value || !form.email.value || !form.message.value) {

          if(form.name.value == '') {form.name.classList.add('required')}
          else { form.name.classList.remove('required') }

          if(form.email.value == '') {form.email.classList.add('required')}
          else { form.email.classList.remove('required') }

          if(form.message.value == '') {form.message.classList.add('required')}
          else { form.message.classList.remove('required') }

          this.setState({ errorMsg: "Please fill out required fields."});
          showErrorMsg();
          return;
      }
      var emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(form.email.value)) {
          form.email.classList.add('required')
          form.name.classList.remove('required')
          form.message.classList.remove('required')
          this.setState({ errorMsg: "Please ensure the email you've entered is valid."});
          showErrorMsg();
          return;
      }
      if(!form.file.value) {
          form.email.classList.remove('required')
          this.setState({ errorMsg: "Please select a PDF to upload."});
          showErrorMsg();
          return;
      }
      this.setState({
        feedbackMsg: "Thank you for your submission."
      });
      form.email.classList.remove('required')
      form.name.classList.remove('required')
      form.message.classList.remove('required')
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.domRef.current.reset(), showSuccessMsg(), document.getElementById("uploadBtn").innerHTML = 'Upload Portfolio PDF'
      )
      .catch(error => this.setState({ feedbackMsg: "Your submission is invalid, please review and try again.",}));
  };

  render() {
    const {
      transitionStatus
    } = this.props

    return (
      <div className={cx('animate__page', transitionStatus)}>
      
      </div>
    );
  }
        getRandomAnimation(){
            const randomAnimationArray = randomAnimation
            const randomItem = items => {
                return items["officeHours"][Math.floor(Math.random() * items["officeHours"].length)]
            }
            const randomAnimationPath = "https://player.vimeo.com/video/" + randomItem(randomAnimationArray) + "?background=1";
            this.setState({
                data: randomAnimationPath
            })
        }

        componentDidMount() {
            this.getRandomAnimation();
        }
}

export default OfficeHours
